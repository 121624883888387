<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 협력업체 -->
          <c-vendor
            :editable="editable"
            :selfFlag="false"
            label="LBL0001859"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 안전보건관리계획 목록 -->
    <c-table
      ref="tasktable"
      title="LBL0001860"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="saiVendorSafetyPlanId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 삭제 -->
          <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteTask" />
          <!-- 추가 -->
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addTask" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskSafetyPlan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            // 수급업체
            label: 'LBL0001848',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width:300px',
          },
          {
            name: 'constructionName',
            field: 'constructionName',
            // 공사
            label: 'LBL0001861',
            align: 'center',
            sortable: true,
            style: 'width:250px',
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 최초등록자
            label: 'LBL0001862',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'regUserDeptName',
            field: 'regUserDeptName',
            // 최초등록부서
            label: 'LBL0001863',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'regDtStrTime',
            field: 'regDtStrTime',
            // 최초등록일시
            label: 'LBL0001864',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'chgUserName',
            field: 'chgUserName',
            // 최근수정자
            label: 'LBL0001865',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'chgUserDeptName',
            field: 'chgUserDeptName',
            // 최근수정부서
            label: 'LBL0001866',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'chgDtStrTime',
            field: 'chgDtStrTime',
            // 최근수정일시
            label: 'LBL0001867',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.vendor.safety.plan.list.url;
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0001868'; // 안전보건관리계획 상세
      this.popupOptions.param = {
        saiVendorSafetyPlanId: row ? row.saiVendorSafetyPlanId : '',
        copyFlag: false
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/vendor/safetyPlanDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택될 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  sopConstructionId: this.popupParam.sopConstructionId,
                  saiVendorSafetyPlanId: item.saiVendorSafetyPlanId,
                }
              }))
            };
            this.$http.request((_result) => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = 'LBL0001869'; // 안전보건관리계획 상세
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/vendor/safetyPlanPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { saiVendorSafetyPlanId: item.saiVendorSafetyPlanId }) === -1) {
            insertList.push({
              sopConstructionId: this.popupParam.sopConstructionId,
              saiVendorSafetyPlanId: item.saiVendorSafetyPlanId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>
